import { createGlobalStyle } from 'styled-components';
import { breakpoint, colors } from './variables';


const GlobalStyles = createGlobalStyle`
  :root {
    --orange: #F49A41;
    --grey: #9C9B9B;
    --black: #000000;
    --white: #ffffff;
    --shadow: 1px 1px 12px 2px #bfbfbf4f;
  }

  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;

  }

  section {
    margin-top: 2rem;
    scroll-margin-top: 70px;

    @media ${breakpoint.md} {
      margin-top: 3rem;
      scroll-margin-top: 110px;
    }

    @media ${breakpoint.lg} {
      scroll-margin-top: 140px;
    }
  }

  .button {
  display: inline-block;
  padding: 0.75rem 1rem;
  border: 2px solid #000;
  background-color: transparent;
  color: #000;
  transition: background-color 0.4s ease;
  text-align: center;
  justify-self: center;
  cursor: pointer;
  transition: all 0.3s;

    &:hover {
      border-color: var(--orange);
      color: var(--orange);
    }
  }

  .link {
    text-decoration: none;
    color: var(--orange);
    transition: color 0.4s ease;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: #b06f2e;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  h1 {
    margin: 0;
  }

  h4 {
    font-weight: 500;
  }

  .shadow {
    box-shadow: 1px 1px 12px 2px #bfbfbf4f;
  }

  p, li {
    a {
      color: #000000;
      transition: color 0.3s ease;

      &:hover {
        color: ${colors.primary};
      }
    }
  }

  ul li {
    line-height: 1.5;
  }

  strong {
    font-weight: 700;
  }

  .underline {
    text-decoration: underline;
  }

  .sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}
`

export default GlobalStyles