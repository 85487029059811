import { createGlobalStyle } from 'styled-components';
import { breakpoint } from './variables';
import "../assets/icomoon/style.css";

const Typography = createGlobalStyle`


  @font-face {
    font-family: "Kole-ExtraLight";
    src: url("../font/KoleExtraLight/font.woff2") format("woff2"), url("../font/KoleExtraLight/font.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  html {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: #5e5e5e;
  }

  h1 {
    font-size: 5rem;
    font-family: "Kole-ExtraLight", sans-serif;
  }

  h2 {
    font-size: 1.2rem;
    margin: 2em 0 1.5em;
    font-weight: 300;

    @media ${breakpoint.md} {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 500;
  }

  p {
    line-height: 1.5;
  }

  b {
    //font-weight: 600;
  }
`
export default Typography