import * as React from 'react'
import Nav from '../components/Nav'
import Footer from "../components/Footer"
import 'normalize.css'
import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import styled from 'styled-components'
import { colors, breakpoint } from '../styles/variables'

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  //background-color: ${colors.background};
  color: var(--black);
`

const Content = styled.div`
  min-height: calc(100vh - 225px);

    @media ${breakpoint.xs} {
      min-height: calc(100vh - 205px);
    }

    @media ${breakpoint.md} {
      min-height: calc(100vh - 175px);
    }

    @media ${breakpoint.lg} {
      min-height: calc(100vh - 135px);
    }
  `

const Layout = ({ children }) => {
  return (

    <>
      <Wrapper>
        <GlobalStyles/>
        <Typography />
        <Nav />
        <Content>{children}</Content>
        <Footer />
      </Wrapper>
    </>
  )
}

export default Layout

