import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import logo from '../assets/images/logos/cestuj.svg'
import menu from '../assets/icons/menu.png'
import { breakpoint } from '../styles/variables'
import Container from './Container'

const StyledNav = styled.nav`
  position: sticky;
  top: 0;
  background-color: var(--white);
  box-shadow: 0px 8px 9px -8px #0000001a;
  padding: 0.5rem 0;
  z-index: 10;

  @media ${breakpoint.lg} {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    z-index: 10;
    width: 100%;
    max-width: 1920px;
    padding: 0;
  }

  &.fixed-nav {
    @media ${breakpoint.lg} {
    position: fixed;
    top: 0;
  }}
`

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;

  @media ${breakpoint.lg} {
    padding: 0;
  }
`

const Menu = styled.ul`
  list-style-type: none;
  margin: 0;
  width: 100vw;
  height: 100vh;
  padding: 2rem 0;
  background-color: #fffffff0;
  z-index: 1000;
  position: fixed;
  right: -200%;
  top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.active {
    right: 0;
    box-shadow: inset  0px 8px 9px -8px #0000001a;
  }

  @media ${breakpoint.lg} {
    padding: 0;
    width: auto;
    height: auto;
    position: unset;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin: 0 auto;
    padding: 0 10%;
  }

  @media ${breakpoint.xl} {
    padding: 0 20%;
  }
`

const MenuItem = styled.li`
  @media ${breakpoint.lg} {

    &:nth-child(3)::after {
      content: "";
      display: block;
      height: 100%;
      width: 340px;
    }
  }
`

const MenuLink = styled(Link)`
  font-family: "Kole-ExtraLight", sans-serif;
  display: block;
  text-decoration: none;
  color: var(--black);
  padding: 1.5rem 1rem;
  transition: color 0.4s ease;
  white-space: nowrap;
  letter-spacing: 0.1em;
  font-weight: 700;
  text-transform: uppercase;

  span {
    position: relative;

    &::before, &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      position: absolute;
      top: 120%;
      background-color: var(--orange);
      opacity: 0;
      transition: all 0.3s;
   }

   &::before {
    left: 50%;
   }
   &::after {
    right: 50%;
   }
  }


  &:hover,
  &:focus-visible,
  &.active-link {

    span {
      &::before, &::after {
        width: 60%;
        opacity: 1;
      }
    }
  }

  @media ${breakpoint.xl} {
    padding: 1.5rem;
  }
`

const MenuLogoLink = styled(Link)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0px 2px 9px 0px #0000001a;
  display: grid;
  place-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;

  &::after {
    position: absolute;
    content: "";
    background-color: white;
    height: 2.5rem;
    width: 120px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  @media ${breakpoint.sm} {
    width: 100px;
    height: 100px;
  }

  @media ${breakpoint.lg} {
    width: 200px;
    height: 200px;

    &::after {
      height: 4rem;
      width: 220px;
    }

    &.fixed-nav {
      width: 150px;
      height: 150px;
    }

  }
`

const Logo = styled.img`
  width: 43px;
  height: auto;
  margin-top: 20px;

  @media ${breakpoint.sm} {
    width: 50%;
    margin-top: 28px;
  }

  @media ${breakpoint.lg} {
    width: 130px;
    margin-top: 0;

    &.fixed-nav {
      width: 75px;
      margin-top: 40px;
    }
  }
  `

const MobileButton = styled.button`
  border: none;
  display: block;
  margin-left: auto;
  width: 24px;
  height: 24px;
  background-size: cover;
  background-image: url(${menu});
  background-color: transparent;

  @media ${breakpoint.lg} {
    display: none;
  }
`
const ScrollArea = styled.div`
    position: absolute;
    display: block;
    width: 100%;
    height: 150px;
    max-width: 1920px;
`

const Nav = () => {

  const [isActive, setActive] = useState(false);
  const handleToggle = () => {
    setActive(!isActive);
  };

  const scrollAreaRef = useRef(null)
  const [ isScrollAreaVisible, setIsScrollAreaVisible ] = useState(false)

  const callbackFunction = (entries) => {
    const [entry] = entries
    setIsScrollAreaVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observedArea = scrollAreaRef.current;
    const observer = new IntersectionObserver(callbackFunction);
    if (observedArea) observer.observe(observedArea)

    return() => {
      if(observedArea) observer.unobserve(observedArea)
    }
  }, [scrollAreaRef])



  return (

    <>
      <ScrollArea ref={scrollAreaRef}></ScrollArea>
      <StyledNav className={!isScrollAreaVisible ? "fixed-nav" : "" }>
          <Wrapper>
              <MenuLogoLink to='/' className={!isScrollAreaVisible ? "fixed-nav" : "" } onClick={handleToggle}>
                <Logo src={logo} aria-label="Domů" className={!isScrollAreaVisible ? "fixed-nav" : "" } width={!isScrollAreaVisible ? "65" : "130" } height={!isScrollAreaVisible ? "71" : "142" } alt="Cestu-j" />
              </MenuLogoLink>
              <Menu className={isActive ? 'active' : null}>
                <MenuItem><MenuLink activeClassName="active-link" to='/'  onClick={handleToggle}><span>Domů</span></MenuLink></MenuItem>
                <MenuItem><MenuLink activeClassName="active-link" to='/#o-mne'  onClick={handleToggle}><span>O nás</span></MenuLink></MenuItem>
                <MenuItem><MenuLink activeClassName="active-link" to='/galerie'  onClick={handleToggle}><span>Galerie</span></MenuLink></MenuItem>
                <MenuItem><MenuLink activeClassName="active-link" to='/sluzby'  onClick={handleToggle}><span>Služby</span></MenuLink></MenuItem>
                <MenuItem><MenuLink activeClassName="active-link" to='/blog' onClick={handleToggle}><span>Blog</span></MenuLink></MenuItem>
                <MenuItem><MenuLink activeClassName="active-link" to='/kontakt'  onClick={handleToggle}><span>Kontakt</span></MenuLink></MenuItem>
              </Menu>
              <MobileButton onClick={handleToggle} aria-label="Menu"></MobileButton>
          </Wrapper>
      </StyledNav>
    </>
  )
}

export default Nav