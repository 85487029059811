import * as React from 'react'
import styled from 'styled-components'
import { breakpoint } from '../styles/variables'

const Wrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 1rem;

  @media ${breakpoint.sm} {
    padding: 0 1.5rem;
  }

  @media ${breakpoint.lg} {
    padding: 0 6rem;
  }
  @media ${breakpoint.xl} {
    padding: 0 14rem;
  }
`

const Container = ({children, className}) => {
  return (
    <Wrapper className={className}>
      {children}
    </Wrapper>
  )
}

export default Container