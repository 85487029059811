import * as React from 'react'
import Container from './Container'
import styled from 'styled-components'
import {colors, breakpoint} from '../styles/variables'

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FooterContent = styled.p`
  font-size: 0.875rem;
  margin: 0.2rem 0;
  //color: #5c5c5c;
  color: #ffffffc2;
  text-align: center;

  a {
    //color: #5c5c5c;¨
    color: #ffffffc2;
  }
`

const StyledFooter = styled.footer`
  padding: 1.5rem;
  margin-top: auto;
  //background-color: white;
  background-color: black;
  font-size: 0.8125rem;

  @media ${breakpoint.md} {
    padding: 2rem;
  }
`

const Contact = styled.a `
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: #ffffffc2;
  transition: color 0.3s ease;
  font-size: 0.875rem;

  .icon {
    transition: color 0.3s ease;
  }

    &:hover {
      color: ${colors.primary};

      .icon {
        color: ${colors.primary};
      }
  }
`

const ContactIcon = styled.span `
  font-size: 1.25rem;
  color: #ffffffc2;
`

const ContactWrapper = styled.div`
  display: grid;
  gap: 0.5em;
  margin-bottom: 1.5em;

  @media ${breakpoint.md} {
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    margin-bottom: 1.5rem;
    gap: 1.5rem;
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <StyledContainer>

        <ContactWrapper>
                <Contact href="mailto:julia@cestu-j.cz"><ContactIcon className="icon icon-mail"></ContactIcon> <span >julia@cestu-j.cz</span></Contact>
                <Contact href="tel:+420739649786"><ContactIcon className="icon icon-phone"></ContactIcon> <span >+420 739 649 786</span></Contact>
                <Contact href="https://www.instagram.com/_cestuj_/"><ContactIcon className="icon icon-instagram"></ContactIcon>_cestuj_</Contact>
        </ContactWrapper>
        <FooterContent>&copy; Cestu-j {new Date().getFullYear()}. Photo by <a href="https://unsplash.com/@sadswim?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">ian dooley</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></FooterContent>

      </StyledContainer>
    </StyledFooter>
  )
}

export default Footer