export const colors = {
  primary: "#F49A41",
  grey: "#9C9B9B",
  black: "#000000",
  white: "#FFFFFF",
  background: "#fafafe",
  orange: "#FF941F",
  yellow: "#E7DD6B",
  green: "#7DBF8D",
  red: "#EC6347",
  ochre: "#C49A68",
  aquamarine: "#3AB4BF",
  blue: "#A7C0D3",
  pink: "#E7C3DD",
  beige: "#D7C6B4",
  turqoise: "#35ACC7",
  lightmint: "#f1f7f7",
  lightorange: "#fffbf6",
  avocado: "#B2B739",
  pine: "#549790",
  oldPink: "#D09293",
  fuchsia: "#C0698C",
  darkBackground: "#404040",
  orangeHover: "#b06f2e",
  //darkBackground: '#26013b',
}

const size = {
  xxs: '320px',
  xs: '375px',
  sm: '425px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
  xxl: '1920px'
}

export const breakpoint = {
  xxs: `(min-width: ${size.xxs})`,
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
};


export const navPosition = '150px'